import { useContext, useState } from 'react';
import StandardizedDataTable from '../../PrimeAids/StandardizedDataTable';
import { ReportContext } from '../../../context/ReportContext';
import { dataRollUp } from '../../PlotlyAids/dataRollUps';
import { formatDateToIsoExtDate, userColumns } from '../../../helpers';
import ReportLineChart from '../../PlotlyAids/ReportLineChart';
import ReportChartLayoutWrapper from '../../PlotlyAids/ReportChartLayoutWrapper';

const UserMeditations = () => {
    const { reportData } = useContext(ReportContext);

    const columns = [
        ...userColumns,
        { field: 'resourceTitle', name: 'Meditation Title', dataType: 'multiselect' },
        { field: 'meditationDate', name: 'Meditation Date', dataType: 'date' },
        { field: 'meditationLength', name: 'Length (seconds)', dataType: 'numeric' },
    ];

    const [filteredData, setFilteredData] = useState([]);

    // const rolledUp = dataRollUp(
    //     filteredData,
    //     (row) => formatDateToIsoExtDate(row.meditationDate),
    //     {
    //         count: true,
    //         sumFields: ['meditationLength'],
    //     }
    // );

    // const chartData = [
    //     {
    //         name: 'Total Meditations',
    //         x: rolledUp.map((x) => x.groupByField),
    //         y: rolledUp.map((x) => x.count),
    //     },
    //     {
    //         name: 'Minutes Meditated',
    //         x: rolledUp.map((x) => x.groupByField),
    //         y: rolledUp.map((x) => x.meditationLengthSum / 60),
    //     },
    // ];

    return (
        <div>
            <StandardizedDataTable
                data={reportData}
                columns={columns}
                onValueChange={setFilteredData}
            />
            {/* <ReportChartLayoutWrapper>
                <ReportLineChart
                    data={chartData}
                    chartTitle="User Meditations Over Time"
                />
            </ReportChartLayoutWrapper> */}
        </div>
    );
};

export default UserMeditations;
