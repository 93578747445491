export const PAGE_TYPE = {
    educatedInvestor: 'educated-investor',
    reports: 'reports',
    default: 'default',
};

export const EI_PAGES = {
    fullAndex: 'ei-full-andex',
    percentageReturns: 'ei-percentage-returns',
    past30Years: 'ei-past-30-years',
};

export const REPORTS_PAGES = {
    userRegistrations: 'report-user-registrations',
    userLogins: 'report-user-logins',
    userCourses: 'report-user-courses',
    userAnswers: 'report-user-answers',
    userResources: 'report-user-resources',
    userCheckups: 'report-user-checkups',
    userMeditations: 'report-user-meditations',
    cobrandAnalytics: 'report-cobrand-analytics',
    userCallsToAction: 'report-user-calls-to-action',
    userSearches: 'report-user-searches',
    userStressChange: 'report-user-stress-change',
    userFinancialWellnessChange: 'report-user-financial-wellness-change',
    userFeedback: 'report-user-feedback',
    userSprouts: 'report-user-sprouts',
    userCreditEnrollment: 'report-user-credit-enrollment',
    userXp: 'report-user-xp',
    userCreditScoreChange: 'report-user-credit-score-change',
    userJournals: 'report-user-journals',
    userMoneyMindfulness: 'report-user-money-mindfulness',
    userAiConversations: 'report-user-ai-conversations',
    userFinancialWellnessUpdate: 'report-user-financial-wellness-update',
    userMoneyPersonality: 'report-user-money-personality',
};

// Some notes about likely origins of the errors for posterity:
// reportRequest error - User would be fully authed and already have other 
//                       successful requests so most likely an issue downstream of the ReportsController
// token error - Getting the token from Auth0. Likely misconfigured Auth0 account or issue on their side
// cobrands error - Problem reaching API server or user account misconfigured
// claims error - Problem reaching API server or user account misconfigured
// generic error - ¯\_(ツ)_/¯
export const ERROR_TEXT = {
    reportRequest: 'There was a problem loading the report. Please try again.',
    token: 'There was a problem with your credentials. Please refresh and try again.',
    cobrands: 'There was a problem getting your Cobrand access. Please refresh and try again.',
    claims: 'There was a problem validating your account. Please refresh and try again.',
    generic: 'There was a communication error with our servers. Please refresh and try again.',
};
