import { useContext, useState } from 'react';
import StandardizedDataTable from '../../PrimeAids/StandardizedDataTable';
import { ReportContext } from '../../../context/ReportContext';
import { dataRollUp } from '../../PlotlyAids/dataRollUps';
import { formatDateToIsoExtDate, userColumns } from '../../../helpers';
import ReportLineChart from '../../PlotlyAids/ReportLineChart';
import ReportChartLayoutWrapper from '../../PlotlyAids/ReportChartLayoutWrapper';

const UserAiConversations = () => {
    const { reportData } = useContext(ReportContext);

    const columns = [
        ...userColumns,
        { field: 'conversationDate', name: 'Conversation Date', dataType: 'date' },
        {
            field: 'topic',
            name: 'Topic',
            dataType: 'string',
            styles: { minWidth: '20rem' },
        },
        // {
        //     field: 'summary',
        //     name: 'Summary',
        //     dataType: 'string',
        //     styles: { minWidth: '30rem' },
        // },
        { field: 'durationSeconds', name: 'Duration (s)', dataType: 'numeric' },
        { field: 'messageCount', name: 'User Messages', dataType: 'numeric' },
        {
            field: 'sentimentScore',
            name: 'Sentiment Score (-1 to 1)',
            dataType: 'numeric',
        },
    ];

    const [filteredData, setFilteredData] = useState([]);

    // const rolledUp = dataRollUp(
    //     filteredData,
    //     (row) => formatDateToIsoExtDate(row.conversationDate),
    //     { count: true }
    // );

    // const chartData = [
    //     {
    //         name: 'Total Conversations',
    //         x: rolledUp.map((x) => x.groupByField),
    //         y: rolledUp.map((x) => x.count),
    //     },
    // ];

    return (
        <div>
            <StandardizedDataTable
                data={reportData}
                columns={columns}
                onValueChange={setFilteredData}
            />
            {/* <ReportChartLayoutWrapper>
                <ReportLineChart
                    data={chartData}
                    chartTitle="AI Conversations Over Time"
                />
            </ReportChartLayoutWrapper> */}
        </div>
    );
};

export default UserAiConversations;
