import { useContext, useState } from 'react';
import StandardizedDataTable from '../../PrimeAids/StandardizedDataTable';
import { ReportContext } from '../../../context/ReportContext';
import { formatDateToIsoExtDate, userColumns } from '../../../helpers';
import { dataRollUp } from '../../PlotlyAids/dataRollUps';
import ReportLineChart from '../../PlotlyAids/ReportLineChart';
import ReportChartLayoutWrapper from '../../PlotlyAids/ReportChartLayoutWrapper';

const UserSprouts = () => {
    const { reportData } = useContext(ReportContext);

    const columns = [
        ...userColumns,
        { field: 'sproutsDate', name: 'Date', dataType: 'date' },
        { field: 'sproutsSourceCategory', name: 'Category', dataType: 'string' },
        {
            field: 'sproutsSource',
            name: 'Source',
            dataType: 'multiselect',
            styles: { minWidth: '20rem' },
        },
        { field: 'sprouts', name: 'Sprouts Awarded', dataType: 'numeric' },
        { field: 'entries', name: 'Entries', dataType: 'numeric' },
    ];

    const [filteredData, setFilteredData] = useState([]);

    // const filteredDataEarnedOnly = filteredData.filter((row) => row.sprouts > 0);
    // const filteredDataRedeemedOnly = filteredData.filter((row) => row.sprouts < 0);

    // const rolledUpEarnedOnly = dataRollUp(
    //     filteredDataEarnedOnly,
    //     (row) => formatDateToIsoExtDate(row.sproutsDate),
    //     {
    //         sumFields: ['sprouts'],
    //     }
    // );
    // const rolledUpRedeemedOnly = dataRollUp(
    //     filteredDataRedeemedOnly,
    //     (row) => formatDateToIsoExtDate(row.sproutsDate),
    //     {
    //         sumFields: ['sprouts'],
    //     }
    // );

    // const chartData = [
    //     {
    //         name: 'Sprouts Earned',
    //         x: rolledUpEarnedOnly.map((x) => x.groupByField),
    //         y: rolledUpEarnedOnly.map((x) => x.sproutsSum),
    //     },
    //     {
    //         name: 'Sprouts Redeemed',
    //         x: rolledUpRedeemedOnly.map((x) => x.groupByField),
    //         y: rolledUpRedeemedOnly.map((x) => x.sproutsSum * -1),
    //     },
    // ];

    return (
        <div>
            <StandardizedDataTable
                data={reportData}
                columns={columns}
                onValueChange={setFilteredData}
            />
            {/* <ReportChartLayoutWrapper>
                <ReportLineChart data={chartData} chartTitle="Sprouts Over Time" />
            </ReportChartLayoutWrapper> */}
        </div>
    );
};

export default UserSprouts;
