import { REPORTS_PAGES } from './constants';
import UserRegistrations from './components/Pages/Reports/UserRegistrations';
import UserLogins from './components/Pages/Reports/UserLogins';
import UserCourses from './components/Pages/Reports/UserCourses';
import UserAnswers from './components/Pages/Reports/UserAnswers';
import UserResources from './components/Pages/Reports/UserResources';
import UserCheckups from './components/Pages/Reports/UserCheckups';
import UserMeditations from './components/Pages/Reports/UserMeditations';
import CobrandAnalytics from './components/Pages/Reports/CobrandAnalytics';
import UserCallsToAction from './components/Pages/Reports/UserCallsToAction';
import UserSearches from './components/Pages/Reports/UserSearches';
import UserStressChange from './components/Pages/Reports/UserStressChange';
import UserFinancialWellnessChange from './components/Pages/Reports/UserFinancialWellnessChange';
import UserFeedback from './components/Pages/Reports/UserFeedback';
import UserSprouts from './components/Pages/Reports/UserSprouts';
import UserCreditEnrollment from './components/Pages/Reports/UserCreditEnrollment';
import UserXp from './components/Pages/Reports/UserXp';
import UserCreditScoreChange from './components/Pages/Reports/UserCreditScoreChange';
import UserJournals from './components/Pages/Reports/UserJournals';
import UserMoneyMindfulness from './components/Pages/Reports/UserMoneyMindfulness';
import UserAiConversations from './components/Pages/Reports/UserAiConversations';
import UserFinancialWellnessUpdate from './components/Pages/Reports/UserFinancialWellnessUpdate';
import UserMoneyPersonality from './components/Pages/Reports/UserMoneyPersonality';

export const reportsMeta = {
    [REPORTS_PAGES.userRegistrations]: {
        id: REPORTS_PAGES.userRegistrations,
        pageComponent: UserRegistrations,
        apiEndpoint: '/reports/user-registrations',
    },
    [REPORTS_PAGES.userLogins]: {
        id: REPORTS_PAGES.userLogins,
        pageComponent: UserLogins,
        apiEndpoint: '/reports/user-logins',
    },
    [REPORTS_PAGES.userCourses]: {
        id: REPORTS_PAGES.userCourses,
        pageComponent: UserCourses,
        apiEndpoint: '/reports/user-courses',
    },
    [REPORTS_PAGES.userAnswers]: {
        id: REPORTS_PAGES.userAnswers,
        pageComponent: UserAnswers,
        apiEndpoint: '/reports/user-answers',
    },
    [REPORTS_PAGES.userResources]: {
        id: REPORTS_PAGES.userResources,
        pageComponent: UserResources,
        apiEndpoint: '/reports/user-resources',
    },
    [REPORTS_PAGES.userCheckups]: {
        id: REPORTS_PAGES.userCheckups,
        pageComponent: UserCheckups,
        apiEndpoint: '/reports/user-checkups',
    },
    [REPORTS_PAGES.userMeditations]: {
        id: REPORTS_PAGES.userMeditations,
        pageComponent: UserMeditations,
        apiEndpoint: '/reports/user-meditations',
    },
    [REPORTS_PAGES.cobrandAnalytics]: {
        id: REPORTS_PAGES.cobrandAnalytics,
        pageComponent: CobrandAnalytics,
        apiEndpoint: '/reports/cobrand-analytics',
    },
    [REPORTS_PAGES.userCallsToAction]: {
        id: REPORTS_PAGES.userCallsToAction,
        pageComponent: UserCallsToAction,
        apiEndpoint: '/reports/user-calls-to-action',
    },
    [REPORTS_PAGES.userSearches]: {
        id: REPORTS_PAGES.userSearches,
        pageComponent: UserSearches,
        apiEndpoint: '/reports/user-searches',
    },
    [REPORTS_PAGES.userStressChange]: {
        id: REPORTS_PAGES.userStressChange,
        pageComponent: UserStressChange,
        apiEndpoint: '/reports/user-stress-change',
    },
    [REPORTS_PAGES.userFinancialWellnessChange]: {
        id: REPORTS_PAGES.userFinancialWellnessChange,
        pageComponent: UserFinancialWellnessChange,
        apiEndpoint: '/reports/user-financial-wellness-change',
    },
    [REPORTS_PAGES.userFeedback]: {
        id: REPORTS_PAGES.userFeedback,
        pageComponent: UserFeedback,
        apiEndpoint: '/reports/user-feedback',
    },
    [REPORTS_PAGES.userSprouts]: {
        id: REPORTS_PAGES.userSprouts,
        pageComponent: UserSprouts,
        apiEndpoint: '/reports/user-sprouts',
    },
    [REPORTS_PAGES.userCreditEnrollment]: {
        id: REPORTS_PAGES.userCreditEnrollment,
        pageComponent: UserCreditEnrollment,
        apiEndpoint: '/reports/user-credit-enrollment',
    },
    [REPORTS_PAGES.userXp]: {
        id: REPORTS_PAGES.userXp,
        pageComponent: UserXp,
        apiEndpoint: '/reports/user-xp',
    },
    [REPORTS_PAGES.userCreditScoreChange]: {
        id: REPORTS_PAGES.userCreditScoreChange,
        pageComponent: UserCreditScoreChange,
        apiEndpoint: '/reports/user-credit-score-change',
    },
    [REPORTS_PAGES.userJournals]: {
        id: REPORTS_PAGES.userJournals,
        pageComponent: UserJournals,
        apiEndpoint: '/reports/user-journals',
    },
    [REPORTS_PAGES.userMoneyMindfulness]: {
        id: REPORTS_PAGES.userMoneyMindfulness,
        pageComponent: UserMoneyMindfulness,
        apiEndpoint: '/reports/user-money-mindfulness',
    },
    [REPORTS_PAGES.userAiConversations]: {
        id: REPORTS_PAGES.userAiConversations,
        pageComponent: UserAiConversations,
        apiEndpoint: '/reports/user-ai-conversations',
    },
    [REPORTS_PAGES.userFinancialWellnessUpdate]: {
        id: REPORTS_PAGES.userFinancialWellnessUpdate,
        pageComponent: UserFinancialWellnessUpdate,
        apiEndpoint: '/reports/user-financial-wellness-update',
    },
    [REPORTS_PAGES.userMoneyPersonality]: {
        id: REPORTS_PAGES.userMoneyPersonality,
        pageComponent: UserMoneyPersonality,
        apiEndpoint: '/reports/user-money-personality',
    },
};
