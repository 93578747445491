import { useContext, useState } from 'react';
import StandardizedDataTable from '../../PrimeAids/StandardizedDataTable';
import { ReportContext } from '../../../context/ReportContext';
import { dataRollUp } from '../../PlotlyAids/dataRollUps';
import { formatDateToIsoExtDate, userColumns } from '../../../helpers';
import ReportLineChart from '../../PlotlyAids/ReportLineChart';
import ReportChartLayoutWrapper from '../../PlotlyAids/ReportChartLayoutWrapper';

const UserCreditScoreChange = () => {
    const { reportData } = useContext(ReportContext);

    const columns = [
        ...userColumns,
        { field: 'creditScoreChangeDate', name: 'Change Date', dataType: 'date' },
        { field: 'creditScoreFromDate', name: 'From Date', dataType: 'date' },
        { field: 'creditScoreToDate', name: 'To Date', dataType: 'date' },
        { field: 'isCurrent', name: 'Current', dataType: 'boolean' },
        { field: 'equifaxEnrolledDate', name: 'Equifax Enrolled Date', dataType: 'date' },
    ];

    const [filteredData, setFilteredData] = useState([]);

    // const rolledUp = dataRollUp(
    //     filteredData,
    //     (row) => formatDateToIsoExtDate(row.creditScoreChangeDate),
    //     {
    //         count: true,
    //     }
    // );

    // const chartData = [
    //     {
    //         name: 'Total Credit Score Changes',
    //         x: rolledUp.map((x) => x.groupByField),
    //         y: rolledUp.map((x) => x.count),
    //     },
    // ];

    return (
        <div>
            <StandardizedDataTable
                data={reportData}
                columns={columns}
                onValueChange={setFilteredData}
            />
            {/* <ReportChartLayoutWrapper>
                <ReportLineChart
                    data={chartData}
                    chartTitle="User Credit Score Changes Over Time"
                />
            </ReportChartLayoutWrapper> */}
        </div>
    );
};

export default UserCreditScoreChange;
