import { useContext, useState } from 'react';
import StandardizedDataTable from '../../PrimeAids/StandardizedDataTable';
import { ReportContext } from '../../../context/ReportContext';
import { formatDateToIsoExtDate, userColumns } from '../../../helpers';
import { dataRollUp } from '../../PlotlyAids/dataRollUps';
import ReportLineChart from '../../PlotlyAids/ReportLineChart';
import ReportChartLayoutWrapper from '../../PlotlyAids/ReportChartLayoutWrapper';

const UserXP = () => {
    const { reportData } = useContext(ReportContext);

    const columns = [
        ...userColumns,
        { field: 'xpDate', name: 'Experience Date', dataType: 'date' },
        { field: 'xp', name: 'Points', dataType: 'numeric' },
        { field: 'xpCategory', name: 'Category', dataType: 'multiselect' },
    ];

    const [filteredData, setFilteredData] = useState([]);

    // const rolledUp = dataRollUp(
    //     filteredData,
    //     (row) => formatDateToIsoExtDate(row.xpDate),
    //     {
    //         sumFields: ['xp'],
    //     }
    // );

    // const chartData = [
    //     {
    //         name: 'XP Earned',
    //         x: rolledUp.map((x) => x.groupByField),
    //         y: rolledUp.map((x) => x.xpSum),
    //     },
    // ];

    return (
        <div>
            <StandardizedDataTable
                data={reportData}
                columns={columns}
                onValueChange={setFilteredData}
            />
            {/* <ReportChartLayoutWrapper>
                <ReportLineChart data={chartData} chartTitle="XP Earned Over Time" />
            </ReportChartLayoutWrapper> */}
        </div>
    );
};

export default UserXP;
