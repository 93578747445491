import { useContext, useState } from 'react';
import StandardizedDataTable from '../../PrimeAids/StandardizedDataTable';
import { ReportContext } from '../../../context/ReportContext';
import { dataRollUp } from '../../PlotlyAids/dataRollUps';
import { formatDateToIsoExtDate, userColumns } from '../../../helpers';
import ReportLineChart from '../../PlotlyAids/ReportLineChart';
import ReportChartLayoutWrapper from '../../PlotlyAids/ReportChartLayoutWrapper';

const UserMoneyPersonality = () => {
    const { reportData } = useContext(ReportContext);

    const columns = [
        ...userColumns,
        { field: 'startDate', name: 'Start Date', dataType: 'date' },
        { field: 'endDate', name: 'End Date', dataType: 'date' },
        { field: 'outlook', name: 'Outlook', dataType: 'multiselect' },
        { field: 'influence', name: 'Influence', dataType: 'multiselect' },
        { field: 'focus', name: 'Focus', dataType: 'multiselect' },
        { field: 'emotions', name: 'Emotions', dataType: 'multiselect' },
        { field: 'bonus', name: 'Bonus', dataType: 'multiselect' },
    ];

    const [filteredData, setFilteredData] = useState([]);

    // const rolledUp = dataRollUp(
    //     filteredData,
    //     (row) => formatDateToIsoExtDate(row.startDate),
    //     { count: true }
    // );

    // const chartData = [
    //     {
    //         name: 'Personality Reports Over Time',
    //         x: rolledUp.map((x) => x.groupByField),
    //         y: rolledUp.map((x) => x.count),
    //     },
    // ];

    return (
        <div>
            <StandardizedDataTable
                data={reportData}
                columns={columns}
                onValueChange={setFilteredData}
            />
            {/* <ReportChartLayoutWrapper>
                <ReportLineChart
                    data={chartData}
                    chartTitle="Money Personality Reports Over Time"
                />
            </ReportChartLayoutWrapper> */}
        </div>
    );
};

export default UserMoneyPersonality;
