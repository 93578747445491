import { useContext, useState } from 'react';
import StandardizedDataTable from '../../PrimeAids/StandardizedDataTable';
import { ReportContext } from '../../../context/ReportContext';
import { dataRollUp } from '../../PlotlyAids/dataRollUps';
import { formatDateToIsoExtDate, userColumns } from '../../../helpers';
import ReportLineChart from '../../PlotlyAids/ReportLineChart';
import ReportChartLayoutWrapper from '../../PlotlyAids/ReportChartLayoutWrapper';

const UserFinancialWellnessUpdate = () => {
    const { reportData } = useContext(ReportContext);

    const columns = [
        ...userColumns,
        { field: 'updateDate', name: 'Update Date', dataType: 'date' },
        {
            field: 'debtStudentLoanUpdate',
            name: 'Student Loan Update',
            dataType: 'boolean',
        },
        { field: 'debtMortgageUpdate', name: 'Mortgage Update', dataType: 'boolean' },
        { field: 'debtAutoUpdate', name: 'Auto Loan Update', dataType: 'boolean' },
        { field: 'debtOtherUpdate', name: 'Other Debt Update', dataType: 'boolean' },
        { field: 'debtNoneUpdate', name: 'No Debt Update', dataType: 'boolean' },
        {
            field: 'debtCreditCardUpdate',
            name: 'Credit Card Update',
            dataType: 'boolean',
        },
        {
            field: 'debtFamilyFriendUpdate',
            name: 'Family/Friend Loan Update',
            dataType: 'boolean',
        },
        { field: 'debtMedicalUpdate', name: 'Medical Debt Update', dataType: 'boolean' },
        {
            field: 'debtPaydayLoanUpdate',
            name: 'Payday Loan Update',
            dataType: 'boolean',
        },
        {
            field: 'debtBusinessUpdate',
            name: 'Business Debt Update',
            dataType: 'boolean',
        },
        {
            field: 'debtInstallmentLoanUpdate',
            name: 'Installment Loan Update',
            dataType: 'boolean',
        },
        {
            field: 'debtLineOfCreditUpdate',
            name: 'Line of Credit Update',
            dataType: 'boolean',
        },
        {
            field: 'debtCollectionsUpdate',
            name: 'Collections Update',
            dataType: 'boolean',
        },
        {
            field: 'savingEmergencyFundUpdate',
            name: 'Emergency Fund Update',
            dataType: 'boolean',
        },
        {
            field: 'savingRetirementUpdate',
            name: 'Retirement Savings Update',
            dataType: 'boolean',
        },
        { field: 'savingCarUpdate', name: 'Car Savings Update', dataType: 'boolean' },
        {
            field: 'savingChildCollegeFundUpdate',
            name: 'Child College Fund Update',
            dataType: 'boolean',
        },
        {
            field: 'savingEducationUpdate',
            name: 'Education Savings Update',
            dataType: 'boolean',
        },
        { field: 'savingHomeUpdate', name: 'Home Savings Update', dataType: 'boolean' },
        {
            field: 'savingParenthoodUpdate',
            name: 'Parenthood Savings Update',
            dataType: 'boolean',
        },
        { field: 'savingPetUpdate', name: 'Pet Savings Update', dataType: 'boolean' },
        {
            field: 'savingStartingBusinessUpdate',
            name: 'Starting Business Savings Update',
            dataType: 'boolean',
        },
        {
            field: 'savingVacationUpdate',
            name: 'Vacation Savings Update',
            dataType: 'boolean',
        },
        {
            field: 'savingWeddingUpdate',
            name: 'Wedding Savings Update',
            dataType: 'boolean',
        },
        { field: 'savingOtherUpdate', name: 'Other Savings Update', dataType: 'boolean' },
        { field: 'savingNoneUpdate', name: 'No Savings Update', dataType: 'boolean' },
        {
            field: 'healthInsuranceUpdate',
            name: 'Health Insurance Update',
            dataType: 'boolean',
        },
        { field: 'payOffBillsUpdate', name: 'Pay Off Bills Update', dataType: 'boolean' },
        {
            field: 'leftoverMoneyMonthlyUpdate',
            name: 'Leftover Money Update',
            dataType: 'boolean',
        },
        {
            field: 'creditCardCarryBalanceUpdate',
            name: 'Credit Card Balance Carry Update',
            dataType: 'boolean',
        },
        { field: 'budgetUpdate', name: 'Budget Update', dataType: 'boolean' },
        { field: 'goalsUpdate', name: 'Goals Update', dataType: 'boolean' },
        {
            field: 'insuranceNoneUpdate',
            name: 'No Insurance Update',
            dataType: 'boolean',
        },
        {
            field: 'insuranceAutoUpdate',
            name: 'Auto Insurance Update',
            dataType: 'boolean',
        },
        {
            field: 'insuranceDisabilityUpdate',
            name: 'Disability Insurance Update',
            dataType: 'boolean',
        },
        {
            field: 'insuranceHomeOrRentalUpdate',
            name: 'Home/Rental Insurance Update',
            dataType: 'boolean',
        },
        {
            field: 'insuranceLifeUpdate',
            name: 'Life Insurance Update',
            dataType: 'boolean',
        },
        {
            field: 'insuranceLongTermCareUpdate',
            name: 'Long-Term Care Insurance Update',
            dataType: 'boolean',
        },
        {
            field: 'insuranceUmbrellaUpdate',
            name: 'Umbrella Insurance Update',
            dataType: 'boolean',
        },
        {
            field: 'insuranceOtherUpdate',
            name: 'Other Insurance Update',
            dataType: 'boolean',
        },
        { field: 'willUpdate', name: 'Will Update', dataType: 'boolean' },
    ];

    const [filteredData, setFilteredData] = useState([]);

    // const rolledUp = dataRollUp(
    //     filteredData,
    //     (row) => formatDateToIsoExtDate(row.updateDate),
    //     { count: true }
    // );

    // const chartData = [
    //     {
    //         name: 'Total Updates',
    //         x: rolledUp.map((x) => x.groupByField),
    //         y: rolledUp.map((x) => x.count),
    //     },
    // ];

    return (
        <div>
            <StandardizedDataTable
                data={reportData}
                columns={columns}
                onValueChange={setFilteredData}
            />
            {/* <ReportChartLayoutWrapper>
                <ReportLineChart
                    data={chartData}
                    chartTitle="Financial Wellness Updates Over Time"
                />
            </ReportChartLayoutWrapper> */}
        </div>
    );
};

export default UserFinancialWellnessUpdate;
