import React, { useContext } from 'react';
import '../../css/SideMenus/SideMenu.css';

import andexChartIcon from '../../images/sideMenu/andex-chart-icon.svg';
import andexChartIconBlack from '../../images/sideMenu/andex-chart-icon-black.svg';
import chartPercentageIcon from '../../images/sideMenu/tabler_percentage.svg';
import chartCalendarIcon from '../../images/sideMenu/tabler_calendar-repeat.svg';
import MenuBlock from './MenuBlock';
import { EI_PAGES, PAGE_TYPE, REPORTS_PAGES } from '../../constants';
import { MenuContext } from '../../context/MenuContext';
import { ThemeContext } from '../../context/ThemeContext';
import { CircularProgress } from '@mui/material';

const SideMenu = () => {
    const { isMenuOpen, clientConsoleAccess, errorText, claimsRequestInFlight } =
        useContext(MenuContext);

    const { logoLarge, logoSmall } = useContext(ThemeContext);

    const andexTitleIcon = (
        <img src={andexChartIcon} style={{ color: '#000' }} alt="Rolling Periods" />
    );

    const andexItems = [
        {
            id: EI_PAGES.fullAndex,
            name: 'Full Andex',
            icon: <img src={andexChartIconBlack} alt="" />,
            pageType: PAGE_TYPE.educatedInvestor,
        },
        {
            id: EI_PAGES.percentageReturns,
            name: 'Percentage returns',
            icon: <img src={chartPercentageIcon} alt="" />,
            pageType: PAGE_TYPE.educatedInvestor,
        },
        {
            id: EI_PAGES.past30Years,
            name: 'Past 30 years',
            icon: <img src={chartCalendarIcon} alt="" />,
            pageType: PAGE_TYPE.educatedInvestor,
        },
    ];

    const reportItems = [
        {
            id: REPORTS_PAGES.userRegistrations,
            name: 'User Registrations',
            icon: <img src={andexChartIconBlack} alt="" />,
            pageType: PAGE_TYPE.reports,
        },
        {
            id: REPORTS_PAGES.userLogins,
            name: 'User Logins',
            icon: <img src={andexChartIconBlack} alt="" />,
            pageType: PAGE_TYPE.reports,
        },
        {
            id: REPORTS_PAGES.userCourses,
            name: 'User Courses',
            icon: <img src={andexChartIconBlack} alt="" />,
            pageType: PAGE_TYPE.reports,
        },
        {
            id: REPORTS_PAGES.userAnswers,
            name: 'User Answers',
            icon: <img src={andexChartIconBlack} alt="" />,
            pageType: PAGE_TYPE.reports,
        },
        {
            id: REPORTS_PAGES.userResources,
            name: 'User Resources',
            icon: <img src={andexChartIconBlack} alt="" />,
            pageType: PAGE_TYPE.reports,
        },
        {
            id: REPORTS_PAGES.userCheckups,
            name: 'User Checkups',
            icon: <img src={andexChartIconBlack} alt="" />,
            pageType: PAGE_TYPE.reports,
        },
        {
            id: REPORTS_PAGES.userFinancialWellnessChange,
            name: 'User Financial Wellness Change',
            icon: <img src={andexChartIconBlack} alt="" />,
            pageType: PAGE_TYPE.reports,
        },
        {
            id: REPORTS_PAGES.userFinancialWellnessUpdate,
            name: 'User Financial Wellness Update',
            icon: <img src={andexChartIconBlack} alt="" />,
            pageType: PAGE_TYPE.reports,
        },
        {
            id: REPORTS_PAGES.userMoneyPersonality,
            name: 'User Money Personality',
            icon: <img src={andexChartIconBlack} alt="" />,
            pageType: PAGE_TYPE.reports,
        },
        {
            id: REPORTS_PAGES.userMeditations,
            name: 'User Meditations',
            icon: <img src={andexChartIconBlack} alt="" />,
            pageType: PAGE_TYPE.reports,
        },
        // This table is broken and does not deploy in Gold but will likely be back
        // {
        //     id: REPORTS_PAGES.cobrandAnalytics,
        //     name: 'Cobrand Analytics',
        //     icon: <img src={andexChartIconBlack} alt="" />,
        //     pageType: PAGE_TYPE.reports,
        // },
        {
            id: REPORTS_PAGES.userCallsToAction,
            name: 'User Calls to Action',
            icon: <img src={andexChartIconBlack} alt="" />,
            pageType: PAGE_TYPE.reports,
        },
        {
            id: REPORTS_PAGES.userSearches,
            name: 'User Searches',
            icon: <img src={andexChartIconBlack} alt="" />,
            pageType: PAGE_TYPE.reports,
        },
        {
            id: REPORTS_PAGES.userStressChange,
            name: 'User Stress Change',
            icon: <img src={andexChartIconBlack} alt="" />,
            pageType: PAGE_TYPE.reports,
        },
        {
            id: REPORTS_PAGES.userFeedback,
            name: 'User Feedback',
            icon: <img src={andexChartIconBlack} alt="" />,
            pageType: PAGE_TYPE.reports,
        },
        {
            id: REPORTS_PAGES.userXp,
            name: 'User XP',
            icon: <img src={andexChartIconBlack} alt="" />,
            pageType: PAGE_TYPE.reports,
        },
        {
            id: REPORTS_PAGES.userSprouts,
            name: 'User Sprouts',
            icon: <img src={andexChartIconBlack} alt="" />,
            pageType: PAGE_TYPE.reports,
        },
        {
            id: REPORTS_PAGES.userCreditEnrollment,
            name: 'User Credit Enrollment',
            icon: <img src={andexChartIconBlack} alt="" />,
            pageType: PAGE_TYPE.reports,
        },
        {
            id: REPORTS_PAGES.userCreditScoreChange,
            name: 'User Credit Score Change',
            icon: <img src={andexChartIconBlack} alt="" />,
            pageType: PAGE_TYPE.reports,
        },
        {
            id: REPORTS_PAGES.userJournals,
            name: 'User Journals',
            icon: <img src={andexChartIconBlack} alt="" />,
            pageType: PAGE_TYPE.reports,
        },
        {
            id: REPORTS_PAGES.userMoneyMindfulness,
            name: 'User Money Mindfulness',
            icon: <img src={andexChartIconBlack} alt="" />,
            pageType: PAGE_TYPE.reports,
        },
        {
            id: REPORTS_PAGES.userAiConversations,
            name: 'User Ai Conversations',
            icon: <img src={andexChartIconBlack} alt="" />,
            pageType: PAGE_TYPE.reports,
        },
    ];

    if (errorText) {
        return <div className="text-lg font-bold text-red-700">{errorText}</div>;
    }

    const noAccess =
        !claimsRequestInFlight &&
        !clientConsoleAccess?.educatedInvestorEnabled &&
        !clientConsoleAccess?.reportsEnabled;

    return (
        <div className={`sidebar ${isMenuOpen ? 'expanded' : 'collapsed'}`}>
            <div className="sidebar-section">
                <div className="flex justify-center my-4">
                    {isMenuOpen && logoLarge && (
                        <img className="sidebar-logo-expanded" src={`https://media-cdn.igrad.com/${logoLarge}`} alt="" />
                    )}
                    {!isMenuOpen && !logoSmall && (
                        <p><strong>CC</strong></p>
                    )}
                    {!isMenuOpen && logoSmall && (
                        <img className="sidebar-logo-collapsed" src={`https://media-cdn.igrad.com/${logoSmall}`} alt="" />
                    )}
                </div>

                {noAccess && <p>No access</p>}

                {claimsRequestInFlight && (
                    <div className="flex justify-center">
                        <CircularProgress />
                    </div>
                )}

                {clientConsoleAccess?.educatedInvestorEnabled && (
                    <MenuBlock
                        title="Andex Chart"
                        titleIcon={andexTitleIcon}
                        items={andexItems}
                        rootClass={'my-3'}
                    />
                )}
                
                {clientConsoleAccess?.reportsEnabled && (
                    <MenuBlock
                        title="Reports"
                        titleIcon={andexTitleIcon}
                        items={reportItems}
                        rootClass={'my-3'}
                    />
                )}
            </div>
        </div>
    );
};

export default SideMenu;
