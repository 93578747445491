import { useContext, useState } from 'react';
import StandardizedDataTable from '../../PrimeAids/StandardizedDataTable';
import { ReportContext } from '../../../context/ReportContext';
import { dataRollUp } from '../../PlotlyAids/dataRollUps';
import { formatDateToIsoExtDate, userColumns } from '../../../helpers';
import ReportLineChart from '../../PlotlyAids/ReportLineChart';
import ReportChartLayoutWrapper from '../../PlotlyAids/ReportChartLayoutWrapper';

const UserAnswers = () => {
    const { reportData } = useContext(ReportContext);

    const columns = [
        ...userColumns,
        { field: 'answerDate', name: 'Answer Date', dataType: 'date' },
        {
            field: 'question',
            name: 'Question',
            dataType: 'string',
            styles: { minWidth: '30rem' },
        },
        {
            field: 'userAnswer',
            name: 'Answer',
            dataType: 'string',
            styles: { minWidth: '20rem' },
        },
        { field: 'correctAnswer', name: 'Correct Answer', dataType: 'string' },
        { field: 'questionType', name: 'Question Type', dataType: 'multiselect' },
        { field: 'questionFormat', name: 'Question Format', dataType: 'multiselect' },
    ];

    const [filteredData, setFilteredData] = useState([]);

    // const rolledUp = dataRollUp(
    //     filteredData,
    //     (row) => formatDateToIsoExtDate(row.answerDate),
    //     {
    //         count: true,
    //     }
    // );

    // const chartData = [
    //     {
    //         name: 'Total Answers',
    //         x: rolledUp.map((x) => x.groupByField),
    //         y: rolledUp.map((x) => x.count),
    //     },
    // ];

    return (
        <div>
            <StandardizedDataTable
                data={reportData}
                columns={columns}
                onValueChange={setFilteredData}
            />
            {/* <ReportChartLayoutWrapper>
                <ReportLineChart data={chartData} chartTitle="User Answers Over Time" />
            </ReportChartLayoutWrapper> */}
        </div>
    );
};

export default UserAnswers;
