import { useContext, useState } from 'react';
import StandardizedDataTable from '../../PrimeAids/StandardizedDataTable';
import { ReportContext } from '../../../context/ReportContext';
import { dataRollUp } from '../../PlotlyAids/dataRollUps';
import { formatDateToIsoExtDate, userColumns } from '../../../helpers';
import ReportLineChart from '../../PlotlyAids/ReportLineChart';
import ReportChartLayoutWrapper from '../../PlotlyAids/ReportChartLayoutWrapper';

const UserMoneyMindfulness = () => {
    const { reportData } = useContext(ReportContext);

    const columns = [
        ...userColumns,
        { field: 'programDate', name: 'Program Date', dataType: 'date' },
        { field: 'programWeek', name: 'Week', dataType: 'multiselect' },
        { field: 'programDay', name: 'Day', dataType: 'multiselect' },
        { field: 'isProgramDayComplete', name: 'Complete', dataType: 'boolean' },
    ];

    const [filteredData, setFilteredData] = useState([]);

    // const rolledUp = dataRollUp(
    //     filteredData,
    //     (row) => formatDateToIsoExtDate(row.programDate),
    //     {
    //         count: true,
    //     }
    // );

    // const chartData = [
    //     {
    //         name: 'Total Money Mindfulness Updates',
    //         x: rolledUp.map((x) => x.groupByField),
    //         y: rolledUp.map((x) => x.count),
    //     },
    // ];

    return (
        <div>
            <StandardizedDataTable
                data={reportData}
                columns={columns}
                onValueChange={setFilteredData}
            />
            {/* <ReportChartLayoutWrapper>
                <ReportLineChart
                    data={chartData}
                    chartTitle="User Money Mindfulness Over Time"
                />
            </ReportChartLayoutWrapper> */}
        </div>
    );
};

export default UserMoneyMindfulness;
