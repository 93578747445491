import React, { createContext, useState, useEffect, useMemo } from 'react';
import Axios from 'axios';
import { setupCache, buildWebStorage } from 'axios-cache-interceptor';
import { useAuth0 } from '@auth0/auth0-react';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const cacheTTL = 10 * 60 * 1000 // 10 minutes
    const instance = Axios.create();
    const axios = setupCache(instance, {
        storage: buildWebStorage(sessionStorage, 'axios-cache:'),
        ttl: cacheTTL
    });
    const [colors, setColors] = useState({});
    const [logoLarge, setLogoLarge] = useState("");
    const [logoSmall, setLogoSmall] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [cobrandName, setCobrandName] = useState("");
    const [cobrandThemeId, setCobrandThemeId] = useState([]);
    const [cobrandThemeData, setCobrandThemeData] = useState({});

    const EI_LOGO_EXPANDED = 'IMAGE/EducatedInvestor/_logo_educated_investor.svg'
    const EI_LOGO_COLLAPSED = ''
    const iGRAD_LOGO_EXPANDED = 'IMAGE/Logos/Color/Default@2x.png'
    const iGRAD_LOGO_COLLAPSED = ''
    const ENRICH_LOGO_EXPANDED = 'IMAGE/Logos/Retina-Color/Enrich@2x.png'
    const ENRICH_LOGO_COLLAPSED = ''

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    useEffect(
        () => {
            const fetchData = async () => {

                if (!isAuthenticated) {
                    return; // Exit early if not authenticated
                }

                try {
                    setIsLoading(true);
                    const token = await getAccessTokenSilently();

                    const staffCobrandsResp = await axios.get(`${API_BASE_URL}/staff/cobrands`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            },
                            id: 'cobrand-info'
                        }
                    );
                    const newCobrandOptions = await staffCobrandsResp.data.map((x) => ({
                        id: x.cobrandId,
                        name: x.name,
                    }));

                    setCobrandName(newCobrandOptions[0].name);

                    const cobrandIds = newCobrandOptions.map((x) => x.id);

                    const cobrandThemeIdResponse = await axios.post(`${API_BASE_URL}/ei/client-console-theme-ids`,
                        {
                            cobrandIds: cobrandIds
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            },
                            id: 'cobrand-theme-ids',
                        });

                    const cobrandThemeIdResponseData = cobrandThemeIdResponse.data

                    setCobrandThemeId(cobrandThemeIdResponseData[0]);
                    setIsLoading(false);

                } catch (e) {
                    console.error(e);
                    setIsLoading(false);
                }
            };
            fetchData();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [getAccessTokenSilently, isAuthenticated]
    );

    useEffect(() => {
        const fetchCobrandTheme = async () => {
            try {
                setIsLoading(true);
                const token = await getAccessTokenSilently();
                const cobrandTheme = await axios.get(`${API_BASE_URL}/internal/clients/cobrands/${cobrandName}/theme`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        },
                        id: 'cobrand-theme',
                    });

                const cobrandThemeDataResponse = await cobrandTheme.data;
                setCobrandThemeData(cobrandThemeDataResponse);
                setIsLoading(false);
            } catch (e) {
                console.error(`Error fetching cobrand theme for ${cobrandName}`)
                setIsLoading(false);
            }
        }
        if (cobrandThemeId.Id === 4) {
            fetchCobrandTheme();
        }
    }, [cobrandThemeId])

    useEffect(() => {
        // CobrandThemeIds
        // 1 = Educated Investor
        // 2 = iGrad
        // 3 = Enrich
        // 4 = Cobrand
        if (cobrandThemeId.Id === 4 && !isLoading) {
            const EXTRACT_COBRAND_COLOR_VARIABLES = /^[a-c][1-7]$/;

            const filteredColors = Object.fromEntries(
                Object.entries(cobrandThemeData).filter(([key]) => EXTRACT_COBRAND_COLOR_VARIABLES.test(key))
            );


            setColors((prevColors) => ({
                ...prevColors,
                A1: `#${filteredColors.a1}`,
                A2: `#${filteredColors.a2}`,
            }));

            setLogoLarge(cobrandThemeData.retina_Logo_Color);
            setLogoSmall(cobrandThemeData.client_Console_Collapsed_Logo)
        }
        else if (cobrandThemeId.Id === 3 && !isLoading) {
            setColors((prevColors) => ({
                ...prevColors,
                A1: "#02539f",
                A2: "#ffffff",
            }));
            setLogoLarge(ENRICH_LOGO_EXPANDED);
            setLogoSmall(ENRICH_LOGO_COLLAPSED);
        }
        else if (cobrandThemeId.Id === 2 && !isLoading) {
            setColors((prevColors) => ({
                ...prevColors,
                A1: "#734dc4",
                A2: "#ffffff",
            }));
            setLogoLarge(iGRAD_LOGO_EXPANDED);
            setLogoSmall(iGRAD_LOGO_COLLAPSED);
        }
        else {
            setIsLoading(false);
            if (!isLoading) {
                setColors((prevColors) => ({
                    ...prevColors,
                    A1: "#68AC37",
                    A2: "#ffffff",
                }));
                setLogoLarge(EI_LOGO_EXPANDED);
                setLogoSmall(EI_LOGO_COLLAPSED);
            }
        }
    }, [cobrandThemeId, cobrandThemeData]);

    return (
        <ThemeContext.Provider value={{ colors, logoLarge, logoSmall }}>
            {children}
        </ThemeContext.Provider>
    );
};